// remove link on logo if its the home page
if (window.location.href === site_url) {
    $("a.logo_link").attr("href", "#");
} else {
    $("a.logo_link").attr("href", site_url);
}

// make these images responsive:
// blog post page images
$(".entry-content img").addClass("img-responsive");


/* cookie policy */

$(document).ready(function() {
    if ($.cookie("acceptsCookies") === "yes") {
        $(".exitMunchMyCookies").closest("#munchMyCookies").hide();
    } else {
        $(".exitMunchMyCookies").click(function() {
            $.cookie("acceptsCookies", "yes", { path: '/' });
            $(this).closest("#munchMyCookies").fadeOut(900);
        });
    }
});
// document ready
/* END cookie policy */


// center things
/*TweenMax.set(".center_me", {
    xPercent: -50,
    yPercent: -50
});

TweenMax.set(".center_me_vertically", {
    yPercent: -50
});

TweenMax.set(".center_me_horizontally", {
    xPercent: -50
});*/

// if (Modernizr.mq('(max-width: 1070px)')) {
//     TweenMax.set(".center_me_1070", {
//         xPercent: -50,
//         yPercent: -50
//     });
// }

var do_centering = function(){
  if (Modernizr.mq('(max-width: 991px)')) {
      TweenMax.set(".mobile_center_xPercent", {
          xPercent: -50,
          left:"50%"
      });
  } else {
    TweenMax.set(".mobile_center_xPercent", { clearProps: "xPercent, left" });
  }
};
$(window).resize(function(){do_centering();});
do_centering();


// fix mobile wodpress admin bar location
var fix_wpadminbar = function(){
    if ($(window).width() < 992) {    
        if ($("#wpadminbar").length) {
            $("#wpadminbar").css("top", "0px");
            var the_top = $("#wpadminbar").offset().top;       
            $("#wpadminbar").css("top", "-" + the_top + "px");
        }
    }
}; 
$(document).ready(function(){    
    $(window).resize(fix_wpadminbar);
    fix_wpadminbar();
});  
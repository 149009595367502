//$("#gform_1 .ginput_container_email").append($("#gform_1 .gform_footer")); // perceptualreality footer form submit fudgery
$(document).on("gform_post_render", function() {
    if (!$("#super_perceptual_submit").length) {
        $("#gform_1 .ginput_container_email").prepend($("#gform_1 .gform_footer")); // perceptualreality footer form submit fudgery
    }
});

$('.slide_switch_buttons .slide_button p').each(function() {
    maxHeight = Math.max($(this).height(), maxHeight);
});

$("[data-form]").on("click", function() {
    var id = "#" + $(this).attr("data-form");
    $(id).css({
        "height": "inherit",
        "opacity": 1,
        "overflow": "visible"
    });
});

// if ($(window).width() < 1025) {

// }

$(".wpdreams_asl_container input[type='search']").on("touchstart", function(e) {
    e.preventDefault();
    e.stopPropagation();
    goToByScroll($(this), 10, 0.0);
    $(this).click().focus();
});

var clickNum = 0;
$(document).on("click", ".place-order input[type=submit]", function() {
    clickNum++;
    if (clickNum == 1) {
        if (!$("#gift_aid_reclaimed").is(":checked")) {
            swal({
                title: "Want to add Gift Aid?",
                text: "If you are a UK taxpayer, you can add Gift Aid to your order. If you Gift Aid your donation, MAIN will receive an additional 28p for every pound you give.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#c1c1c1",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                closeOnConfirm: false,
                closeOnCancel: false
            }, function(isConfirm) {
                if (isConfirm) {
                    swal("Cancelled", "You have chose not to add Gift Aid to your donation", "error");
                    $('#gift_aid_reclaimed').prop('checked', false);
                } else {
                    swal("Gift Aid Added!", "Gift Aid has been added to your donation.", "success");
                    $('#gift_aid_reclaimed').prop('checked', true);
                }
            });
            return false;
        }
    }
});

var service_animation;


$(document).on("click", ".service_button .expand_button", function() {
    var wrapper = $(this).parent();

    if ($(this).hasClass("close_button")) {

        $(this).removeClass("close_button");
        service_animation.reverse();

    } else {
        $(".expand_button").removeClass("close_button");
        $(this).addClass("close_button");

        if (typeof service_animation !== "undefined") {
            service_animation.pause(0).kill();
        }

        service_animation = new TimelineMax({
            paused: false
        });

        if ($(window).width() > 991) {
            service_animation.set($(".middle_services_row"), {
                    minHeight: $(".middle_services_row").outerHeight(true)
                })
                .set($(".service_buttons_wrapper"), {
                    minHeight: $(".service_buttons_wrapper").outerHeight(true)
                });
        }
        if ($(window).width() > 991) {
            $('.service_button').each(function(i) {
                var left_pos = 16.6;
                service_animation.set($(this), {
                    position: "absolute",
                    left: left_pos * i + "%",
                    zIndex: "1"
                });
            });

            var current_left_pos = $(wrapper).css("left");

            service_animation.set($(wrapper), {
                    zIndex: "999"
                })
                .to($(".service_button").not($(wrapper)), 0.5, {
                    overflow: "hidden",
                    left: current_left_pos,
                    boxShadow: "0px"
                }, 0);
        }
        service_animation.to($(this), 0.5, {
                rotation: 45
            }, 0)
            .set($(".expand_content", wrapper), {
                display: "inline-block",
                opacity: 1.0
            }, 0)
            .set($(".expand_content", wrapper).children(), {
                display: "none",
                opacity: 0.0
            }, 0)


        // .set($(wrapper), {
        //     textAlign: "left"
        // })
        var alignment = "left",
            start_at = 1.0;
        if ($(window).width() < 992) {
            alignment = "center";
            start_at = 0.0;
        }
        service_animation.to($(wrapper), 0.5, {
            textAlign: alignment,
            width: "100%",
            overflow: "hidden",
            top: "0",
            left: "0",
            position: "relative",
            backgroundImage: "url('" + site_url + "wp-content/themes/sage-8.4.2/dist/images/landing_gradient_bg.jpg')",
            padding: "25px",
            ease: Power4.easeOut
        }, start_at);

        if ($(window).width() > 991) {
            service_animation.to($("h4", wrapper), 0.5, {
                marginTop: 0,
                y: -12,
                x: -9
            }, start_at);
        }

        var alignment_y = 10,
            alignment_x = 40;
        if ($(window).width() < 992) {
            alignment_y = 0;
            alignment_x = 0;
        }
        service_animation.to($("svg", wrapper), 0.5, {
                height: 214,
                width: 214,
                rotation: 67.5,
                y: alignment_y,
                x: alignment_x
            }, start_at)
            // .to($(".services_desc"), 0.5, {
            //     marginTop: "185px"
            // }, 1.0)
            .to($(".expand_content", wrapper).children(), 0.5, {
                display: "inline-block",
                opacity: 1.0
            }, start_at + 0.5);
    }
});

$(document).on("click", "#click_to_search", function() {
    var text_to_search_for = $(".text_to_search_for").val();
    console.log(text_to_search_for);
    start_navigation_away("", site_url + "?s=" + text_to_search_for);
});

// open close the archive expand areas
$(".row_year .icon, .row_year .the_year").click(function() {
    var posts = $(this).closest(".row_year").next(".years_posts");
    var all_posts = $(this).closest(".main_content").find(".years_posts");

    var icon = $(this).closest(".row_year").find(".icon");
    var all_icon = $(this).closest(".main_content").find(".icon");


    if ($(posts).css("display") === "none") {
        // resets
        $(all_icon).addClass("open_me").removeClass("close_me");
        $(all_posts).slideUp();

        // actions
        $(icon).addClass("close_me").removeClass("open_me");
        $(posts).slideDown();
    } else {
        // reset the one we are clicking on
        $(icon).addClass("open_me").removeClass("close_me");
        $(posts).slideUp();
    }
});

$(document).ready(function() {
    setTimeout(function() {
        TweenMax.to("#the_page, #munchMyCookies", 0.25, {
            opacity: 1.0
        });
    }, 300);
});

TweenMax.set(".center_me_vertically", {
    yPercent: -50
});

var do_heights_for_centering = function() {

    if ($(window).width() > 991) {
        $(".bl_content_wrapper").css("min-height", $(".main_content_container").css("height"));
    } else {
        TweenMax.set($(".bl_content_wrapper"), {
            clearProps: "min-height"
        });
    }

};

$(window).load(function() {
    do_heights_for_centering();
}); 

$(window).resize(function() {
    do_heights_for_centering();
});
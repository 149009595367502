/* load images from divs depending on screen size */
$(document).ready(function() {
    var do_images = function() {
        var setURL;
        $.each($(".SURGE_iamge_swap"), function() {
            current_URL = $(this).attr('src');
            if (Modernizr.mq('(max-width: 991px)')) {
                setURL = $(this).attr('data-mobile');
            } else {
                setURL = $(this).attr('data-desktop');
            }
            if (typeof current_URL === typeof undefined) {
                // must be fist go - is div - so convert it to an image
                $(this).replaceWith(function() {
                    return $("<img>", {
                        'class': this.className,
                        src: setURL,
                        alt: $(this).attr('alt'),
                        'data-desktop': $(this).attr('data-desktop'),
                        'data-mobile': $(this).attr('data-mobile')
                    });
                });
            } else {
                if (current_URL !== setURL) {
                    $(this).attr("src", setURL);
                }
            }
        });
    };
    $(window).on("resize", do_images);
    do_images();

});

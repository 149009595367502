var search_products = function(sort_by) {

    var bearing_bar_finish = "";
    var ball_proof = "";
    var span = "";

    if (typeof sort_by === "undefined") {
        sort_by = "ASC";
    }

    if ($(".search_results_white_box").css("display") === "block") {
        sort_by = $("#sort_by").val();
    }

    $(".bearing_bar_group input").each(function() {
      if ($(this).is(':checked')) {
        bearing_bar_finish = $(this).attr("data-attr");
      }
    });

    $(".ball_proof_group input").each(function() {
      if ($(this).is(':checked')) {
        ball_proof = $(this).attr("data-attr");
      }
    });

    $(".span_group input").each(function() {
      if ($(this).is(':checked')) {
        span = $(this).attr("data-attr");
      }
    });

    // Validation
    if (bearing_bar_finish !== "" && ball_proof !== "" && span !== "") {

        preloader.active(true);
        
        // Do stuff
        var search_data = [];

        search_data.push({
            'bearing_bar_finish': bearing_bar_finish,
            'ball_proof': ball_proof,
            'span': span,
            'sort_by': sort_by
        });

        //console.log(search_data);

        $.ajax({
            type: "POST",
            url: MyAjax.ajaxurl,
            data: {
                "action": "search_products_by_attributes",
                "postCommentNonce": MyAjax.postCommentNonce,
                "search_data": search_data
            },
            dataType: "html",
            success: function(response) {
                preloader.active(false);
                $(".show_on_search_click").css("display", "block");
                if (response !== "") {
                    $(".inner_replace_me").replaceWith("<div class='inner_replace_me'>" + response + "</div>");
                } else {
                    $(".inner_replace_me").replaceWith("<div class='inner_replace_me'>" + '<h3>No products found.</h3>' + "</div>");
                }
                goToByScroll(".show_on_search_click h1", 0, 1);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                preloader.active(false);
                swal("An Error Occured", "Please try again later.", "error");
                console.log(XMLHttpRequest.responseText);
                console.log(textStatus);
                console.log(errorThrown);
            }
        });
        return false;
    } else {
        sweetAlert("Please fill in the required fields.", "Make sure that you have selected an option per type.", "error");
    }
};

$(document).on("click", ".product_finder_page_wrapper #search_products", function() {
    search_products();
});

$('#sort_by').on('change', function() {
    var sort_by = $(this).val();
    search_products(sort_by);
});